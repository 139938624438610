import React from "react"
import { Container, Layout, SEO, Grid, TextRow } from "../components"

const Project = ({ pageContext }) => {
  const { title, tags, content } = pageContext

  return (
    <Layout hideProfile>
      <SEO title={`Project: ${title}`} />
      <Container>
        <h1>{title}</h1>
        <Grid>
          <TextRow>
            <div className="project__tags">{tags}</div>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </TextRow>
        </Grid>
      </Container>
    </Layout>
  )
}

export default Project
